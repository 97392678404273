.admin-sec {
    text-align: center;
    padding: 20px;
}

.admin-logo-cont {
    margin-bottom: 20px;
}

.admin-logo-img {
    width: 400px;
}

.tab-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab {
    margin-top: 50px;
    padding: 10px 20px;
    /* margin: 0 10px; */
    cursor: pointer;
    background-color: #ffb600;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    transition: background-color 0.3s ease;
}

.tab:hover {
    background-color: #5400bc;
    color: white;
}

.active-tab {
    background-color: #5400bc;
    color: white;
    border-color: #5400bc;
}

.tab-content {
    margin-top: 20px;
}

.product_head {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.product_table {
    margin-top: 20px;
    /* margin: 0 auto; */
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 20px;
}

.logout-btn {
    margin-top: 20px;
    padding: 10px 15px;
    text-decoration: none;
    background-color: #ff4c4c;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.add-product-btn{
    font-size: 18px;
    padding: 5px 10px;
    border: none;
    background: #5400bc;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.add-product-btn:hover{
    background: #ffce50;
    color: black;
}

.bill-btn-admin{
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    color: white;
    background: #ff4c4c;
    border-radius: 5px;
}
 

@media(max-width:480px){
    .admin-logo-img{
        width: 300px;
    }
    .admin-sec{
        padding: 0;
    }
    .product_table{
        width: 90%;
    }
}