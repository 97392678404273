.comp_table {
    margin: 0;
    padding: 0;
    overflow-x: auto;
}

.comp_table table {
    width: 100%;
    border-collapse: collapse;
}


.comp_td, .comp_th {
    border: 1px solid #dddddd;
    padding: 20px;
    text-align: left;
}

.comp_th {
    color: #fff;
    text-align: center;
    background-color: #3d0188;
}
.comp_td{
    background-color: #ffeab5;
}

/* .comp_tr:nth-child(even) {
    background-color: #ffd466;
    font-weight: 600;
}

.comp_tr:nth-child(odd) {
    background-color: #be34fe;
    color: black;
    font-weight: 600;
} */

.edit-btn-admin {
    padding: 5px 10px;
    border: none;
    background: #275400;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.delete-btn-admin{
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    background: #ff3b3b;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.edit-btn-admin:hover {
    background: #1eff00;
    color: #000;
}
.delete-btn-admin:hover{
    background-color: #000;
}
@media(max-width:480px){
    .comp_th{
        font-size: 12px;
        padding: 10px;
    }
    .comp_td{
        font-size: 12px;
        padding: 10px;
    }
}