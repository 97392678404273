/* Radial Menu Container */
.radial-menu {
    position: fixed;
    bottom: 20px; /* Adjust the distance from bottom */
    right: 20px;  /* Adjust the distance from right */
    z-index: 1000; /* Ensure it stays above other elements */
  }
  
  /* WhatsApp Button Styling */
  .whatsapp-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; /* Button size */
    height: 60px; /* Button size */
    background-color: #25D366; /* WhatsApp green */
    border-radius: 50%; /* Circular button */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    transition: transform 0.3s ease-in-out;
  }
  
  .whatsapp-btn:hover {
    transform: scale(1.1); /* Slightly enlarges on hover */
  }
  
  /* WhatsApp Icon Styling */
  .whatsapp-icon {
    width: 30px; /* Adjust the size of the icon */
    height: 30px;
    filter: brightness(0) invert(1); /* Ensures the icon is visible on dark background */
  }
  