.comp_table{
    /* margin-top: 100px; */
    width: 100%;
}
.product-head{
    margin-top: 100px;
    font-size: 28px;
    font-weight: 600;
}
.search-input{
    padding: 10px;
    font-size: 18px;
    /* border: none; */
    outline: none;
    border-radius: 10px;
    width: 50%;
    /* background: #5400bc; */
}
.search-container{
    margin-bottom: 15px;
    margin-top: 15px;
}
.product-input{
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    outline: none;
    /* border: none; */
}
.form-section {
    width: 800px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  .form-group {
    margin-bottom: 15px;
  }

  .form-label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    display: block;
    margin-bottom: 5px;
  }
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    transition: border-color 0.2s ease-in-out;
  }
  
  .form-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .error-message {
    color: #ff4d4d;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .form-submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #5400bc;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .form-submit-btn:hover {
    background-color: #8c2eff;
  }
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
.product-submit{
    margin-top: 20px;
    background: #5400bc;
    color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 18px;
    outline: none;
    border: none;
}
.category-header{
    font-size: 18px;
    font-weight: 600;
}
.product-submit:hover{
    background: #ffb600;
}
@media(max-width:680px){
    .comp-table-sec{
        overflow-x: scroll;
    }
    .form-section{
        width: 90%;
    }
    .quantity{
        width: 100%;
    }
}