
.modal-overlay {
    position: fixed;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: scroll;
    /* margin-top: 20px; */
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    position: relative;
    margin-top: 90px;
}

/* .admin-logo-cont {
    text-align: center;
    margin-bottom: 20px;
} */

/* .admin-logo-img {
    max-width: 150px;
} */
.total-amt{
    text-align: center;
    font-size: 20px;
}
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f44336;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.h2 {
    text-align: center;
    margin-bottom: 20px;
}

.bill-details {
    margin-bottom: 20px;
}

.bill-details1 {
    display: flex;
    justify-content: space-between;
}

.bill-col1, .bill-col2 {
    width: 48%;
}



.address-bill {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 50%;
}

.product-table {
    margin-top: 20px;
}
.address-bill-div{
    display: flex;
    justify-content: space-evenly;
}

.product-table table {
    width: 100%;
    border-collapse: collapse;
}

.product-table th, .product-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.product-table th {
    background-color: #f4f4f4;
}

.print-btn {
    display: block;
    width: 100%;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}

.print-btn:hover {
    background-color: #45a049;
}
