/* src/components/Footer.css */

/* Base Styles */
.footer {
  background: url('../../Assets/Pictures/footerbg.png') no-repeat center center;
  background-size: cover;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-left{
  margin-top: 30px;
  /* align-items: center; */
  width: 50%;
  display: flex;
  flex-direction: column;
}
.footer-left1 {
  display: flex;
  align-items: center;
  /* width: 50%; */
  justify-content: space-evenly;
}
.footer-left2{
  margin-top: 20px;
display: flex;
align-items: center;
justify-content: center;
}
.footer-right{
  width: 50%;
}
.footer-right-logo-cont{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.footer-right-logo-img{
  width: 120px;
}
.footer-bot-p{
  font-size: 15px;
  font-weight: 600;
}

.footer-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}



.company-info {
  margin-left: 10px;
}

.footer-icon {
  font-size: 1.5rem;
  color: #fff;
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer-item {
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
}

.footer-item:hover {
  color: #ddd;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  /* bottom: 0; */
  font-size: 0.875rem;
  background: rgba( 0, 0, 0, 0.6 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

/* Responsive Styles */
@media (max-width: 768px) {
  

  .footer-left,
  .footer-right {
    margin-bottom: 20px;
  }

  .footer-right {
    gap: 10px;
  }

  .footer-icon {
    font-size: 1.2rem;
  }

  .company-info {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px;
  }
  .footer-right-logo-img{
    width: 90px;
  }

  .footer-content {
    flex-direction: column;
    /* align-items: center; */
    padding: 0 10px;
  }

  .footer-left{
    width: 100%;
    justify-content: space-evenly;
    /* justify-content: center; */
    margin-bottom: 15px;
  }

  .footer-left {
    flex-direction: column;
    /* align-items: center; */
  }
  .footer-left2{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-right{
    width: 100%;
  }
  .footer-right-logo-cont{
    /* margin-left: 120px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .company-info {
    margin-left: 0;
    text-align: center;
  }

  .footer-right {
    gap: 5px;
  }

  .footer-icon {
    font-size: 2rem;
  }

  .footer-bottom {
    font-size: 0.75rem;
    padding: 10px;
  }
  
}
