.contact-page{
    display: flex;
    flex-direction: column;
}
.contact-h1{
    margin-top: 100px;
    font-size: 24px;
}
.contact-head{
    font-size: 22px;
    font-weight: 500;
}
.contact-info{
    display: flex;
    justify-content: space-evenly;
}
.shop-location{
    margin-top: 30px;
    margin-bottom: 30px;
}
.contact-link{
    text-decoration: none;
    font-size: 18px;
    color: #5400bc;
}
.contact-link:hover{
    color: #ff2f2f;
}
@media(max-width:480px){
    .contact-info{
        flex-direction: column;
    }
    .shop-location{
        width: 400;
        height: 150;
        overflow: scroll;
    }
}