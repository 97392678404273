/* src/components/AdminLogin.css */

  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    /* background-color: #e0e0e0; */
  }
  
  .login-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #5400bc;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  .logo-sec{
    width: 100%;
  }
  .logo-img-sec{
    width: 50%;
  }

  
  .login-btn:hover {
    background-color: #ffb600;
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .login-form {
      width: 90%;
      padding: 15px;
    }
  
    .login-btn {
      font-size: 14px;
      padding: 8px;
    }
  }
  